import { createRouter, createWebHistory } from "vue-router";
import { getAuth } from "firebase/auth";
import generatedRoutes from "../generatedRoutes.json";

// Lazy load each component
const AboutUs = () => import("../views/general/aboutus.vue");
const Auth = () => import("../views/authentication/auth.vue");
const Blog = () => import("../views/blog/blog.vue");
const Contact = () => import("../views/general/contact.vue");
const CookiePolicy = () => import("../views/general/cookiepolicy.vue");
const FAQ = () => import("../views/general/faq.vue");
const HomeView = () => import("../views/home.vue");
const Messages = () => import("../views/messages.vue");
const PasswordReset = () => import("../views/authentication/passwordreset.vue");
const PageNotFound = () => import("../components/notfound/notfound.vue");
const PrivacyPolicy = () => import("../views/general/privacypolicy.vue");
const Sessions = () => import("../views/findsessions.vue");
const SettingsUserGeneral = () => import("../views/user/settings/general.vue");
const SettingsUserProfile = () => import("../views/user/settings/profile.vue");
const SettingsUserProfilePicture = () =>
  import("../views/user/settings/profile.vue");
const SettingsUserBasicInformation = () =>
  import("../views/user/settings/profile.vue");
const SettingsUserInterestAreas = () =>
  import("../views/user/settings/profile.vue");
const SettingsTrainerProfile = () =>
  import("../views/trainer/settings/profile.vue");
const SettingsTrainerInformation = () =>
  import("../views/trainer/settings/profile.vue");
const SettingsTrainerCommunicationTools = () =>
  import("../views/trainer/settings/profile.vue");
const SettingsTrainerCertifications = () =>
  import("../views/trainer/settings/profile.vue");
const SettingsTrainerProfilePicture = () =>
  import("../views/trainer/settings/profile.vue");
const SettingsAvailability = () =>
  import("../views/trainer/settings/availability.vue");
const SettingsTrainerSessionsManagement = () =>
  import("../views/trainer/settings/sessionsmanagement.vue");
const SettingsTrainerMySession = () =>
  import("../views/trainer/settings/session.vue");
const SettingsTrainerMySessions = () =>
  import("../views/trainer/settings/mysessions.vue");
const SettingsTrainerMyExplorers = () =>
  import("../views/trainer/settings/myexplorers.vue");
const SettingsTrainerMyPayments = () =>
  import("../views/trainer/settings/mypayments.vue");
const TermsAndConditions = () =>
  import("../views/general/termsandconditions.vue");
const Trainers = () => import("../views/findtrainers.vue");
const TrainerDashboard = () =>
  import("../views/trainer/dashboard/dashboard.vue");
const TrainerProfileSessionBookingPayment = () =>
  import("../views/trainer/profile/sessionbooking/payment.vue");
const TrainerProfileSessionBookingPaymentStatus = () =>
  import(
    "../views/trainer/profile/sessionbooking/paymentstatus/paymentstatus.vue"
  );
const TrainerProfileSessionBookingSessionStatus = () =>
  import(
    "../views/trainer/profile/sessionbooking/sessionstatus/sessionstatus.vue"
  );
const TrainerRegister = () => import("../views/trainer/register/register.vue");
const TrainerRegisterSuccess = () =>
  import("../views/trainer/register/registersuccess.vue");
const TrainerRegistrationStatus = () =>
  import("../views/trainer/register/registrationstatus.vue");
const UserDashboard = () => import("../views/user/dashboard/dashboard.vue");
const UserMySession = () => import("../views/user/overview/session.vue");
const UserMySessions = () => import("../views/user/overview/mysessions.vue");
const UserMyTrainers = () => import("../views/user/overview/mytrainers.vue");
const VerifyEmail = () => import("../views/authentication/verifyemail.vue");

const staticRoutes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      title: "NetworkTal - Advance Your Career & Master New Skills",
      description:
        "Connect with expert trainers for one-on-one learning in career development and personal growth. Master new skills and hobbies with personalized guidance.",
      canonicalUrl: "https://www.networktal.com",
    },
  },
  {
    path: "/sitemap",
    name: "Sitemap",
    component: () => import("../views/sitemap.vue"),
    meta: {
      title: "NetworkTal - Sitemap",
      description: "Browse the complete sitemap of NetworkTal.",
      canonicalUrl: "https://www.networktal.com/sitemap",
    },
  },
  {
    path: "/blog",
    name: "Blog",
    component: Blog,
    meta: {
      title: "NetworkTal - Blog | Insights & Updates",
      description:
        "Stay updated with insights, tips, and industry news on skill development, coaching, and personal growth. Learn from NetworkTal's expert trainers.",
      canonicalUrl: "https://www.networktal.com/blog",
    },
  },
  {
    path: "/trainers",
    name: "Trainers",
    component: Trainers,
    meta: {
      title:
        "NetworkTal - Find Expert Trainers | Personalized Skill Development",
      description:
        "Find top-rated trainers for one-on-one coaching in tech, business, wellness, and more. Advance your skills and career with personalized mentorship.",
      canonicalUrl: "https://www.networktal.com/trainers",
    },
  },
  {
    path: "/trainer/:id",
    name: "Trainer",
    component: () => import("../views/trainer/profile/profile.vue"), // Lazy-loaded component
    meta: {
      title: "NetworkTal - Trainer Profile",
      description: "View the profile details of a NetworkTal trainer.",
    },
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: Sessions,
    meta: {
      title: "NetworkTal - Book Personalized One-on-One Learning Sessions",
      description:
        "Book personalized sessions with industry experts in tech, design, and personal development. One-on-one coaching to enhance your skills and growth.",
      canonicalUrl: "https://www.networktal.com/sessions",
    },
  },
  {
    path: "/trainer/sessionbooking/payment",
    component: TrainerProfileSessionBookingPayment,
    meta: {
      title: "NetworkTal - Payment",
      description: "Complete your payment for the session.",
      requiresAuth: true,
    },
  },
  {
    path: "/trainer/sessionbooking/paymentstatus",
    component: TrainerProfileSessionBookingPaymentStatus,
    meta: {
      title: "NetworkTal - Payment Status",
      description: "View the status of your payment.",
      requiresAuth: true,
    },
  },
  {
    path: "/trainer/sessionbooking/sessionstatus",
    component: TrainerProfileSessionBookingSessionStatus,
    meta: {
      title: "NetworkTal - Session Status",
      description: "View the status of your session.",
      requiresAuth: true,
    },
  },
  {
    path: "/becometrainer/register",
    name: "TrainerRegister",
    component: TrainerRegister,
    meta: {
      title: "NetworkTal - Become a Trainer | Share & Earn",
      description:
        "Join NetworkTal as a trainer to share your expertise, mentor others, and earn. Set your schedule, teach online, and connect with eager learners.",
      canonicalUrl: "https://www.networktal.com/becometrainer/register",
      requiresAuth: true,
    },
  },
  {
    path: "/becometrainer/register/success",
    name: "TrainerRegisterSuccess",
    component: TrainerRegisterSuccess,
    meta: {
      title: "NetworkTal - Become a Trainer | Success",
      description: "NetworkTal - Become a Trainer | Success",
      requiresAuth: true,
    },
  },
  {
    path: "/becometrainer/register/status",
    name: "TrainerRegistrationStatus",
    component: TrainerRegistrationStatus,
    meta: {
      title: "NetworkTal - Become a Trainer | Status",
      description: "NetworkTal - Become a Trainer | Status",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Auth,
    meta: {
      title: "NetworkTal - Login or Sign Up",
      description: "Login or Sign Up to NetworkTal.",
    },
  },
  {
    path: "/passwordreset",
    name: "PasswordReset",
    component: PasswordReset,
    meta: {
      title: "NetworkTal - Reset Password",
      description: "Reset the password to your NetworkTal account.",
    },
  },
  {
    path: "/verifyemail",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: {
      title: "NetworkTal - Verify Email",
      description: "Verify the email to your NetworkTal account.",
      requiresAuth: true,
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Auth,
    meta: {
      title: "NetworkTal - Login or Sign Up",
      description: "Login or Sign Up to NetworkTal.",
    },
  },
  {
    path: "/settings/user/dashboard",
    name: "UserDashboard",
    component: UserDashboard,
    meta: {
      title: "NetworkTal - Explorer - Dashboard",
      description: "Manage your Explorer account through the dashboard.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user/general",
    name: "SettingsUserGeneral",
    component: SettingsUserGeneral,
    meta: {
      title: "NetworkTal - User - General Settings",
      description: "Manage your User general settings.",
    },
  },
  {
    path: "/settings/user/profile",
    name: "SettingsUserProfile",
    component: SettingsUserProfile,
    default: SettingsUserProfilePicture,
    children: [
      {
        path: "basicinformation",
        name: "SettingsUserBasicInformation",
        component: SettingsUserBasicInformation,
      },
      {
        path: "picture",
        name: "SettingsUserProfilePicture",
        component: SettingsUserProfilePicture,
      },
      {
        path: "interestareas",
        name: "SettingsUserInterestAreas",
        component: SettingsUserInterestAreas,
      },
    ],
    meta: {
      title: "NetworkTal - Explorer - Profile Settings",
      description: "Manage your User profile settings.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user/mytrainers",
    name: "UserMyTrainers",
    component: UserMyTrainers,
    meta: {
      title: "NetworkTal - Explorer - My Trainers",
      description: "View the trainers that you have booked sessions with.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user/mysessions",
    name: "UserMySessions",
    component: UserMySessions,
    meta: {
      title: "NetworkTal - Explorer - My Sessions",
      description: "View the sessions that you have booked.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user/mysessions/:id",
    name: "UserMySession",
    component: UserMySession,
    props: true,
    meta: {
      title: "NetworkTal - Explorer - My Session",
      description: "View the details of a session that you have booked.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/dashboard",
    name: "TrainerDashboard",
    component: TrainerDashboard,
    meta: {
      title: "NetworkTal - Trainer - Dashboard",
      description: "Manage your Trainer account through the dashboard.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/profile",
    name: "SettingsTrainerProfile",
    component: SettingsTrainerProfile,
    children: [
      {
        path: "information",
        name: "SettingsTrainerInformation",
        component: SettingsTrainerInformation,
      },
      {
        path: "communicationtools",
        name: "SettingsTrainerCommunicationTools",
        component: SettingsTrainerCommunicationTools,
      },
      {
        path: "certifications",
        name: "SettingsTrainerCertifications",
        component: SettingsTrainerCertifications,
      },
      {
        path: "picture",
        name: "SettingsTrainerProfilePicture",
        component: SettingsTrainerProfilePicture,
      },
    ],
    meta: {
      title: "NetworkTal - Trainer - Profile Settings",
      description: "Manage your Trainer profile settings.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/availability",
    name: "SettingsAvailability",
    component: SettingsAvailability,
    meta: {
      title: "NetworkTal - Trainer - Availability",
      description: "Manage your Trainer availability.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/sessions",
    name: "SettingsTrainerSessionsManagement",
    component: SettingsTrainerSessionsManagement,
    meta: {
      title: "NetworkTal - Trainer - Sessions Management",
      description: "Manage your Trainer session types.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/mysessions",
    name: "SettingsTrainerMySessions",
    component: SettingsTrainerMySessions,
    meta: {
      title: "NetworkTal - Trainer - My Sessions",
      description: "View the sessions that explorers have booked with you.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/mysessions/:id",
    name: "SettingsTrainerMySession",
    component: SettingsTrainerMySession,
    props: true,
    meta: {
      title: "NetworkTal - Trainer - My Session",
      description:
        "View the details of a session that an explorer has booked with you.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/myexplorers",
    name: "SettingsTrainerMyExplorers",
    component: SettingsTrainerMyExplorers,
    meta: {
      title: "NetworkTal - Trainer - My Explorers",
      description: "View the explorers that have booked sessions with you.",
      requiresAuth: true,
    },
  },
  {
    path: "/settings/trainer/mypayments",
    name: "SettingsTrainerMyPayments",
    component: SettingsTrainerMyPayments,
    meta: {
      title: "NetworkTal - Trainer - My Payments",
      description: "View your payment history and earnings.",
      requiresAuth: true,
    },
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
    meta: {
      title: "NetworkTal - Chat",
      description: "Chat with NetworkTal Trainers and explorers.",
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    meta: {
      title: "NetworkTal - Frequently Asked Questions",
      description:
        "Get answers to common questions about NetworkTal, including booking sessions, becoming a trainer, payments, and account management.",
      canonicalUrl: "https://www.networktal.com/faq",
    },
  },
  {
    path: "/aboutus",
    name: "AboutUs",
    component: AboutUs,
    meta: {
      title: "NetworkTal - About Us",
      description:
        "Learn about NetworkTal’s mission to connect learners with skilled trainers for personalized coaching and skill development.",
      canonicalUrl: "https://www.networktal.com/aboutus",
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: {
      title: "NetworkTal - Privacy Policy",
      description:
        "Review NetworkTal's Privacy Policy to learn how we protect your data and ensure a safe experience on our platform.",
      canonicalUrl: "https://www.networktal.com/privacy-policy",
    },
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: TermsAndConditions,
    meta: {
      title: "NetworkTal - Terms and Conditions",
      description:
        "Read NetworkTal's Terms and Conditions for details on service usage, booking policies, trainer requirements, and more.",
      canonicalUrl: "https://www.networktal.com/terms-and-conditions",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "NetworkTal - Contact Us",
      description:
        "Reach out to NetworkTal with any questions or support needs. We're here to help with account inquiries, bookings, and trainer support.",
      canonicalUrl: "https://www.networktal.com/contact",
    },
  },
  {
    path: "/cookie-policy",
    name: "CookiePolicy",
    component: CookiePolicy,
    meta: {
      title: "NetworkTal - Cookie Policy",
      description:
        "Understand how NetworkTal uses cookies to enhance user experience and track site usage. Review our Cookie Policy here.",
      canonicalUrl: "https://www.networktal.com/cookie-policy",
    },
  },
];

// Convert JSON data to router-compatible format
const dynamicRoutes = [
  ...generatedRoutes.trainers.map((route) => ({
    path: route.path,
    component: () => import("../views/trainer/profile/profile.vue"), // Lazy-loaded component
    meta: {
      title: route.title,
      description: route.description,
      canonicalUrl: `https://www.networktal.com${route.path}`,
    },
  })),
  ...generatedRoutes.blogPosts.map((route) => ({
    path: route.path,
    component: () => import("../views/blog/blogpost.vue"), // Lazy-loaded component
    meta: {
      title: route.title,
      description: route.description,
      canonicalUrl: `https://www.networktal.com${route.path}`,
    },
  })),
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...staticRoutes,
    ...dynamicRoutes,
    {
      path: "/:pathMatch(.*)*",
      name: "PageNotFound",
      component: PageNotFound,
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;

router.afterEach((to) => {
  // Set the page title
  document.title =
    to.meta.title ||
    "NetworkTal - Connect with skilled professionals and learn new skills";

  // Update meta description
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      to.meta.description ||
        "NetworkTal is a platform that connects skilled professionals with individuals that want to learn new skills through personalized one-to-one meetings. Find a trainer today!"
    );
  } else {
    // If not already present, add the meta tag for description
    const newMeta = document.createElement("meta");
    newMeta.name = "description";
    newMeta.content =
      to.meta.description ||
      "NetworkTal is a platform that connects skilled professionals with individuals that want to learn new skills through personalized one-to-one meetings. Find a trainer today!";
    document.head.appendChild(newMeta);
  }

  // Update or remove the canonical URL
  let link = document.querySelector("link[rel='canonical']");
  if (to.meta.canonicalUrl) {
    if (link) {
      // Update the existing canonical link
      link.setAttribute("href", to.meta.canonicalUrl);
    } else {
      // Create a new canonical link if it doesn't exist
      link = document.createElement("link");
      link.setAttribute("rel", "canonical");
      link.setAttribute("href", to.meta.canonicalUrl);
      document.head.appendChild(link);
    }
  } else if (link) {
    // Remove the canonical link if no canonical URL is provided in the meta
    document.head.removeChild(link);
  }
});

router.beforeEach((to, from, next) => {
  const user = getAuth();
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (!from.name) {
    setTimeout(() => {
      if (requiresAuth && !user.currentUser) {
        next("/login?from=" + to.fullPath);
      } else {
        next();
      }
    }, 1500);
  } else {
    if (requiresAuth && !user.currentUser) {
      next("/login?from=" + to.fullPath);
    } else {
      next();
    }
  }
});

// route to login and verifyemail
router.beforeEach(async (to, from, next) => {
  const currentUser = getAuth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    if (!currentUser) {
      next("/login?from=" + to.fullPath);
    } else {
      const isEmailVerified = await new Promise((resolve, reject) => {
        currentUser
          .reload()
          .then(() => {
            resolve(currentUser.emailVerified);
          })
          .catch(reject);
      });

      if (!isEmailVerified && to.name !== "VerifyEmail") {
        next("/verifyemail");
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
